import { twMerge } from 'tailwind-merge';
import { LuLoader2 } from 'react-icons/lu';

function Loader({ className = '', ...otherProps }: { className?: string }) {
  return (
    <LuLoader2
      className={twMerge(
        'relative top-0 animate-spin w-full h-full z-100 text-gray-300',
        className
      )}
      {...otherProps}
    />
  );
}

export default Loader;
